@import "../../../variables";
@import "../../../mixins";
@import "../../../helperClasses";

.content-slider {
    position: relative;
    .slider-image-block {
        margin-bottom: 20px;
    }
    .small-title {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 20px;
        letter-spacing: 0.65px;
        text-transform: uppercase;
    }
    h3 {
        font-size: 18px;
        line-height: 24px;
    }
    p {
        font-size: 16px;
        font-weight: $font-weight-semibold;
        line-height: 25px;
        strong {
            font-size: 13px;
            line-height: 16px;
        }
    }
    .button-arrow {
        opacity: 0;
        transition: .5s all;
        margin-top: 20px;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &:hover {
        .button-arrow {
            @include media-breakpoint-up(lg) {
                opacity: 1;
                transition: .5s all;
            }
        }
    }
    .product {
        .product-tile {
            .tile-footer {
                margin-bottom: 0;
            }
        }
    }
}


.kii-content-slider {
    .product-slide {
        width: 293px !important;
        @include media-breakpoint-up(sm) {
            width: 273px !important;
        }
        @include media-breakpoint-up(md) {
            width: 247px !important;
        }
        @include media-breakpoint-up(lg) {
            width: 314px !important;
        }
        @include media-breakpoint-up(xl) {
            width: 255px !important;
        }
        @include media-breakpoint-up(xxl) {
            width: 300px !important;
        }
        .slider-image-block {
            img {
                object-fit: cover;
                height: 266px;
                @include media-breakpoint-up(sm) {
                    height: 240px;
                }
                @include media-breakpoint-up(md) {
                    height: 215px;
                }
                @include media-breakpoint-up(lg) {
                    height: 279px;
                }
                @include media-breakpoint-up(xl) {
                    height: 223px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 270px;
                }
            }
        }
        .image-container,
        .article-block {
            img {
                object-fit: cover;
                height: 266px;
                @include media-breakpoint-up(sm) {
                    height: 240px;
                }
                @include media-breakpoint-up(md) {
                    height: 215px;
                }
                @include media-breakpoint-up(lg) {
                    height: 279px;
                }
                @include media-breakpoint-up(xl) {
                    height: 223px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 270px;
                }
            }
        }
    }
}

.custom-scroll-bar {
    position: relative;
    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $brand-secondary;
        position: absolute;
        bottom: 8px;
    }
}
.product-slider-container {
    @include media-breakpoint-up(sm) {
        overflow-x: auto;
    }
    .product-slider-wrapper {
        flex-wrap: nowrap;
        .product-slide {
            margin-right: 8.33333%;   
        }
    }
}

.kii-content-slide {
    &.content-large-image-tile-block {
        flex: 0 0 auto;
        width: 80%;
        @include media-breakpoint-up(sm) {
            width: 364px;
        }
        @include media-breakpoint-up(md) {
            width: 244px;
        }
        @include media-breakpoint-up(lg) {
            width: 314px;
        }
        @include media-breakpoint-up(xl) {
            width: 340px;
        }
        @include media-breakpoint-up(xxl) {
            width: 404px;
        }
    }
    &.content-small-image-tile-block {
        flex: 0 0 auto;
        width: 197px;
        @include media-breakpoint-up(sm) {
            width: 182px;
        }
        @include media-breakpoint-up(md) {
            width: 122px;
        }
        @include media-breakpoint-up(lg) {
            width: 157px;
        }
        @include media-breakpoint-up(xl) {
            width: 170px;
        }
        @include media-breakpoint-up(xxl) {
            width: 200px;
        }
    }
    &.content-large-image-tile-block,
    &.content-small-image-tile-block {
        .slider-image-block,
        .image-container,
        .article-block {
            img {
                object-fit: cover;
                height: 193px;
                @include media-breakpoint-up(sm) {
                    height: 221px;
                }
                @include media-breakpoint-up(md) {
                    height: 171px;
                }
                @include media-breakpoint-up(lg) {
                    height: 225px;
                }
                @include media-breakpoint-up(xxl) {
                    // height: inherit;
                    // object-fit: none;
                    height: 230px;
                }
            }
        }
    }
    .article-block {
        padding: 0;
    }
}

